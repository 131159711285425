<template>
  <v-container fluid>
    <!-- {{ getParams() }} -->
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <v-menu ref="fechaDialog" v-model="modalFechas" :close-on-content-click="false" :nudge-right="-17"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="selectedRangueFecha" label="Días" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240" no-title
                scrollable v-model="dialogVarSelectedRangueFecha" range :max="disabledFecha" color="primary"
                header-color="primary">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.fechaDialog.save(dialogVarSelectedRangueFecha)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table disable-pagination :options.sync="options" :hide-default-footer="true" :headers="headers"
      :items="items">
      <template v-slot:top>
        <v-row no-gutters>
          <v-spacer>

          </v-spacer>
          <v-btn x-small @click="items=[], applyFilters()" class="mt-3 ml-1 mr-1 flo" color="primary">
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn x-small @click="applyFilters" class="mt-3 ml-1 mr-1 flo" color="primary">
            Búscar
          </v-btn>

          <v-btn x-small @click="exportToExcel" class="mt-3 ml-1 mr-1 flo" color="success">
            Exportar a Excel
          </v-btn>
          <v-btn x-small @click="exportToPDF" class="mt-3 ml-1 mr-1 flo" color="success">
            Exportar a PDF
          </v-btn>
        </v-row>
      </template>
      <template v-slot:header>
        <tr>
          <td v-for="header in filteredHeaders" :key="header.value">
            <v-text-field @keydown.enter="applyFilters()" dense v-if="header.type === 'date' || header.type === 'datetime' || header.type === 'dateUTC'" v-model="filters[header.value] "
              :label="`${header.text}`" hide-details solo></v-text-field>
            <v-text-field @keydown.enter="applyFilters()" dense v-if="header.type === 'string'" v-model="filters[header.value]"
              :label="`${header.text}`" hide-details solo></v-text-field>
            <v-text-field @keydown.enter="applyFilters()" dense v-if="header.type === 'integer'" type="number" step="1"
              v-model.number="filters[header.value]" :label="`${header.text}`" hide-details solo></v-text-field>
            <v-select @change="applyFilters()" clearable dense v-if="header.type === 'boolean'" v-model="filters[header.value]"
              :items="booleanOptions" :label="`${header.text}`" hide-details solo></v-select>
            <!-- Add other input types as needed -->
          </td>
        </tr>

      </template>
      <template v-slot:[`item.deudor_nombre_completo`]="{ item }">
        {{ item.deudor_nombre_completo }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ fnGetDateTime(item.createdAt) }}
      </template>
      <template v-slot:[`item.flag_tiene_pedido`]="{ item }">
        {{ formatCellValue(item.flag_tiene_pedido, 'boolean') }}
      </template>
      <template v-slot:[`item.fecha_campania`]="{ item }">
        {{ fnGetDateUTC(item.fecha_campania) }}
      </template>
    </v-data-table>
    <v-row>
      <!-- PAGINACIÓN -->
      <v-col cols="12">
        <v-col offset="9" cols="3">
          <v-select v-model="pageSize" :items="pageSizes" label="Registros por página"
            @change="handlePageSizeChange"></v-select>
        </v-col>
        <v-col class="">
          Total de registros: {{ totalRegistros }}
          <v-pagination class="float-right" v-model="page" :length="totalPages" total-visible="15"
            next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
        </v-col>
      </v-col>
      <!-- FIN PAGINACIÓN -->
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
require('moment/locale/es.js');
import { mapActions } from 'vuex';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      //variables para la fecha
      modalFechas: false,
      dialogVarSelectedRangueFecha: null,
      selectedRangueFecha: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas
      disabledFecha: moment().format('YYYY-MM-DD'),
      //
      booleanOptions: [
        { text: 'Sí', value: 1 },
        { text: 'No', value: 0 },
      ],
      //pagination
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
      totalRegistros: 0,
      //pagination fin
      headers: [
        { text: 'id', value: 'id', type: 'integer' },
        { text: 'Estado', value: 'estado', type: 'string', width: 180 },
        { text: 'Con pedido', value: 'flag_tiene_pedido', type: 'boolean' },
        { text: 'Fecha evaluación', value: 'createdAt', type: 'datetime', width: 180 },
        { text: 'Nombre persona', value: 'deudor_nombre_completo', type: 'string', width: 180  },
        { text: 'Dirección', value: 'consejera_direccion', type: 'string', width: 180  },
        { text: 'Teléfono', value: 'numero_de_celular', type: 'string', width: 130 },
        { text: 'Fecha de campaña', value: 'fecha_campania', type: 'dateUTC', width: 180 },
        { text: 'Sector', value: 'sector', type: 'string' },
        { text: 'Region', value: 'region', type: 'string', width: 120 },
        { text: 'División', value: 'division', type: 'string', width: 120 },
        { text: 'No. de eventos', value: 'eventos', type: 'integer' },
        { text: 'tipo de cliente', value: 'buro_tipo_de_usuario', type: 'string' },
        // Add other headers with types
      ],
      items: [],
      filters: {
        id: '',
        estado: '',
        flag_tiene_pedido: '',
        createdAt: '',
        deudor_nombre_completo: '',
        consejera_direccion: '',
        numero_de_celular: '',
        fecha_campania: '',
        sector: '',
        region: '',
        division: '',
        eventos: '',
        buro_tipo_de_usuario: '',
      },
      filterConfig: {
        id: true,
        estado: true,
        flag_tiene_pedido: true,
        createdAt: true,
        deudor_nombre_completo: true,
        consejera_direccion: true,
        numero_de_celular: true,
        fecha_campania: true,
        sector: true,
        region: true,
        division: true,
        eventos: true,
        buro_tipo_de_usuario: true,
      },
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((header) => this.filterConfig[header.value]);
    },
    isFilterChanged() {
      return Object.values(this.filters).some((filter) => filter != '');
    },
  },
  watch: {
    options: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        this.main();
      },
      deep: true,
      immediate: true,
    },
    /** parametrización por fechas con el calendario*/
    dialogVarSelectedRangueFecha(newValue) {
      this.selectedRangueFecha = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
  },
  methods: {

    async exportToPDF() {
      try {
        // Obtener todos los registros
        const allRecords = await this.getAllRecords();

        // Crear el documento PDF en orientación vertical
        const doc = new jsPDF('p', 'pt', 'a4');

        // Preparar los datos para la tabla
        const tableData = allRecords.map((record) =>
          this.headers.map((header) =>
            this.formatCellValue(record[header.value], header.type)
          )
        );

        // Definir las columnas
        const tableColumns = this.headers.map((header) => ({
          header: header.text,
          dataKey: header.value,
        }));

        // Calcular el ancho de página disponible
        const pageWidth = doc.internal.pageSize.getWidth();

        // Añadir la tabla al PDF
        doc.autoTable({
          head: [tableColumns.map((col) => col.header)],
          body: tableData,
          startY: 20,
          styles: {
            fontSize: 6,
            cellPadding: 2,
          },
          headStyles: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontSize: 7,
            fontStyle: 'bold',
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // ID column
            1: { cellWidth: 'auto' }, // Adjust as needed for other columns
          },
          alternateRowStyles: { fillColor: [245, 245, 245] },
          margin: { top: 20, right: 10, bottom: 20, left: 10 },
          tableWidth: pageWidth - 20, // Full width minus margins
        });

        // Guardar el archivo PDF
        doc.save('LISTADO DE CANTIDAD EVENTOS POR GESTION SECTOR NUEVOS Y REINGRESOS.pdf');
      } catch (error) {
        console.error('Error al exportar a PDF:', error);
        // Aquí puedes agregar un manejo de errores más robusto, como mostrar un mensaje al usuario
      }
    },

    formatCellValue(value, type) {
      if (type === 'datetimeUTC') {
        return this.fnGetDateUTCTime(value);
      }
      if (type === 'dateUTC') {
        return this.fnGetDateUTC(value);
      }
      if (type === 'date') {
        return this.fnGetDate(value);
      }
      if (type === 'datetime') {
        return this.fnGetDateTime(value);
      }
      if (type === 'boolean') {
        if (value == 1) {
          return 'Si'
        }
        else if (value == 0) {
          return 'No'
        } else {
          return '-'
        }
      }
      // Manejar otros tipos según sea necesario
      return value;
    },
    /** fin pdf */
    async exportToExcel() {
      try {
        let XLSX = require('xlsx');
        // Obtener todos los registros
        const allRecords = await this.getAllRecords();

        // Preparar los datos para el Excel
        const excelData = allRecords.map((record) => {
          let rowData = {};
          this.headers.forEach((header) => {
            // Usar el 'text' como nombre de columna y 'value' para obtener el dato
            rowData[header.text] = this.formatCellValue(
              record[header.value],
              header.type
            );
          });
          return rowData;
        });

        // Crear una hoja de trabajo
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Crear un libro de trabajo y agregar la hoja
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Registros');

        // Guardar el archivo
        XLSX.writeFile(wb, 'LISTADO DE CANTIDAD EVENTOS POR GESTION SECTOR NUEVOS Y REINGRESOS.xlsx');
      } catch (error) {
        console.error('Error al exportar a Excel:', error);
        // Aquí puedes agregar un manejo de errores más robusto, como mostrar un mensaje al usuario
      }
    },

    async getAllRecords() {
      const totalRecords = this.totalRegistros;
      const params = this.getParams();
      params['$limit'] = totalRecords;
      params['$skip'] = 0;

      try {
        const response = await this.findGestiones({ query: params });
        console.log('todas las gestiones: ', response);
        return response.data;
      } catch (error) {
        console.error('Error al obtener todos los registros:', error);
        throw error;
      }
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    fnGetDateUTCTime(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    getYearMonth(dateString) {
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Extract the year and month
      const year = date.getFullYear();
      // Months are zero-indexed, so add 1 and pad with zero if necessary
      const month = String(date.getMonth() + 1).padStart(2, '0');

      // Concatenate year and month
      return `${year}${month}`;
    },
    //
    ...mapActions('GestionesEventosComentarios', {
      findGestiones: 'find',
      createGestiones: 'create',
      patchGestiones: 'patch',
    }),
    /**paginacion */
    handlePageChange(value) {
      this.page = value;
      this.main();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.main();
    },
    /**paginacion fin */
    getParams() {
      const params = {};
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      /** */
      /**fechas */
      /** */
      let split1 = this.selectedRangueFecha[0].split('-');
      let split2 = this.selectedRangueFecha[1].split('-');
      var firstDay = new Date(split1[2], split1[1] - 1, split1[0], 0, 0, 0);
      var lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);
      //console.log('fechas para createdAt', firstDay, lastDay);
      params['createdAt'] = {
        $gte: firstDay,
        $lte: lastDay,
      };
      /** */
      /**fechas */

      Object.keys(this.filters).forEach((key) => {
        let filterValue = this.filters[key];
        if (filterValue === '' || filterValue === null || filterValue === undefined) return;

        const header = this.headers.find((h) => h.value === key);
        if (!header) return;

        if (header.type === 'string' && !header.multi) {
          // Trim leading and trailing spaces
          filterValue = filterValue.trim();
          params[key] = { $like: `%${filterValue}%` };
        } else if (header.type === 'integer') {
          params[key] = filterValue;
        } else if (header.type === 'boolean') {
          params[key] = filterValue;
          params[key] = filterValue === 0 ? { $eq: null } : Boolean(filterValue);
        } else if (header.type === 'date') {
          filterValue = filterValue.trim();

          let split_filterValue = filterValue.split('-');
          var firstDay = new Date(split_filterValue[2], split_filterValue[1] - 1, split_filterValue[0], 0, 0, 0);
          var lastDay = new Date(split_filterValue[2], split_filterValue[1] - 1, split_filterValue[0], 23, 59, 59);


          params[key] = {
            $gte: firstDay,
            $lte: lastDay,
          };

        }
        else if (header.type === 'dateUTC') {
          filterValue = filterValue.trim();

          let split_filterValue = filterValue.split('-');

          let firstDay = new Date(split_filterValue[2], split_filterValue[1] - 1, split_filterValue[0] - 2, 23, 59, 59);
          let lastDay = new Date(split_filterValue[2], split_filterValue[1] - 1, split_filterValue[0] - 1, 23, 59, 59);


          params[key] = {
            $gte: firstDay,
            $lte: lastDay,
          };

        }
        // Add more conditions for other types like 'betweendate'
      });

      /** */
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? -1 : 1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { id: -1 };
      }

      return params;
    },
    /** */
    main() {
      this.findGestiones({ query: this.getParams() }).then((r) => {
        this.items = r.data;
        this.totalRegistros = r.total;
        this.totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.pageSize)
        );
      });
    },
    /** */
    applyFilters() {
      this.page = 1;
      this.main();
      // Function to apply filters and fetch data
      // eslint-disable-next-line no-unused-vars
      const params = this.getParams();
      // Use the params to fetch data from Feathers service
    },
  },
  mounted() {
    this.main();
  },
};
</script>
